// Importing Components from node_modules
import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav, Navbar as ReactNavbar, NavDropdown } from 'react-bootstrap';
import axios from 'axios';

// Importing Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faUserCircle } from '@fortawesome/pro-regular-svg-icons';

// Importing Project-defined Components
import { deployment_type as deploymentType } from '../deployment';
import { endSessionUrl } from '../utils/auroraXapi';

// Importing styles
import '../styles/navbar_and_footer/Navbar.scss';
import '../styles/navbar_and_footer/NavbarMobile.scss';

// Importing Images
import logo from '../img/logos/logo.svg';

/*
 * Passing in the className allows the styling of the Navbar to be customized based
 * on what page we are on
 */
const Navbar = ({ className }) => {
  // Used to determine the current route (aka pathname) of the application
  const location = useLocation();
  let navigate = useNavigate();

  // Used for the purpose of tracking user sessions
  const [auth, setAuth] = useState(false);
  const [username, setUserName] = useState('');
  const [, setError] = useState('');

  function signOutProcedure() {
    axios(endSessionUrl, {
      method: 'post',
      withCredentials: 'true',
    })
      .then((resp) => resp.data)
      .catch((err) => setError(err));

    sessionStorage.removeItem('auth');
    sessionStorage.removeItem('account_info');

    setAuth(false);

    // Make sure that if a user is on a path that requires authentication
    // that they are then redirected
    if (location.pathname.includes('/account')) {
      navigate('/');
    }
  }
  // gets window dimensions for conditional rendering of navbar
  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = React.useState({
      windowLength: window.innerWidth,
      windowHeight: window.innerHeight,
    });
    useEffect(() => {
      const handleResize = () => {
        setWindowDimensions({
          windowLength: window.innerWidth,
          windowHeight: window.innerHeight,
        });
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
  };
  /*
    which dropdown subnav is currently active (displayed)
    the state should be set to only one of the SUBNAV_ constants for clarity
  */
  const SUBNAV_CONJUNCTION = 'conjunction';
  const SUBNAV_MORE = 'more';
  const SUBNAV_CITIZEN = 'citizen';
  const SUBNAV_OBSERVATORY = 'observatory';
  const SUBNAV_NONE = 'none';
  const SUBNAV_DATA = 'data';
  const SUBNAV_DEVELOPERS = 'developers';
  const [subnav, setSubnav] = useState(SUBNAV_NONE);
  const MORE_PAGES = ['/about', '/contact'];
  const { windowLength } = useWindowDimensions();
  const VO_PAGES = [
    '/vo',
    '/keogramist',
    '/keogramist/about',
    '/eventExplorer',
  ];
  const CONJUNCTION_PAGES = [
    '/conjunctionSearch/standard',
    '/conjunctionSearch/expert',
  ];
  const CITIZEN_SCIENCE_PAGES = ['/gallery'];
  const DATA_PAGES = [
    '/data/about',
    '/data/availability',
    '/data/statistics',
    '/data/standards',
  ];
  const DEVELOPER_PAGES = ['/data/apiLibraries'];

  /*
    Whenever a Route change is detected, check the current path and change state to accurately
    display the active route on the dropdown. This approach takes into account when the user
    navigates using the footer.

    Additionally, each time the location changes (i.e. new page loaded) the user will be loaded in
    at the top of the page, rather than possibly loading in at the middle/bottom.
  */
  useEffect(() => {
    window.scrollTo(0, 0);
    if (CONJUNCTION_PAGES.includes(location.pathname)) {
      setSubnav(SUBNAV_CONJUNCTION);
    } else if (VO_PAGES.includes(location.pathname)) {
      setSubnav(SUBNAV_OBSERVATORY);
    } else if (CITIZEN_SCIENCE_PAGES.includes(location.pathname)) {
      setSubnav(SUBNAV_CITIZEN);
    } else if (MORE_PAGES.includes(location.pathname)) {
      setSubnav(SUBNAV_MORE);
    } else if (DATA_PAGES.includes(location.pathname)) {
      setSubnav(SUBNAV_DATA);
    } else if (DEVELOPER_PAGES.includes(location.pathname)) {
      setSubnav(SUBNAV_DEVELOPERS);
    } else {
      setSubnav(SUBNAV_NONE);
    }

    setAuth(sessionStorage.getItem('auth') ? true : false);

    if (sessionStorage.getItem('account_info')) {
      if (JSON.parse(sessionStorage.getItem('account_info')).first_name) {
        let name = JSON.parse(
          sessionStorage.getItem('account_info'),
        ).first_name;
        name = name.charAt(0).toUpperCase() + name.slice(1);
        setUserName(name);
      }
    } else {
      setUserName('');
    }
  }, [location]);

  // set the staging/dev badge
  let badge = null;
  if (deploymentType === 'staging') {
    badge = (
      <span className='hidden-xs'>
        <span
          style={{ fontSize: '12px' }}
          className='badge badge-pill badge-primary p-2'
        >
          Staging Version
        </span>
      </span>
    );
  } else if (deploymentType === 'development') {
    badge = (
      <span className='hidden-xs'>
        <span
          style={{ fontSize: '12px' }}
          className='badge badge-pill badge-primary p-2'
        >
          Local Development Version
        </span>
      </span>
    );
  }

  return (
    <>
      <ReactNavbar
        className={className}
        expand='xl'
        variant='dark'
        collapseOnSelect={true}
      >
        {/* Logo & AuroraX on nav */}
        <ReactNavbar.Brand>
          <NavLink to='/'>
            <img
              src={logo}
              alt='auroraX logo'
              className='d-inline-block align-top'
            />{' '}
            <span>AuroraX</span>{' '}
          </NavLink>
          {badge}
        </ReactNavbar.Brand>

        {/* Toggle for {sm | md | lg } devices */}
        <ReactNavbar.Toggle aria-controls='basic-navbar-nav' />

        <ReactNavbar.Collapse id='basic-navbar-nav'>
          <Nav className='ml-auto'>
            <Nav.Link href='https://swarm-aurora.com' target='_blank'>
              Swarm-Aurora
            </Nav.Link>

            {/* The Conjunction Search dropdown */}
            {/*
              The `title` prop is used to replace the default dropdown icon with a `react-icon`
              The `active` prop is used to remove any lingering styles that result from clicking an
              element that is not a `NavDropdown.Item`
            */}
            <NavDropdown
              alignRight
              id='basic-nav-dropdown'
              className={
                subnav === SUBNAV_CONJUNCTION ? 'nav-link--active' : ''
              }
              title={
                <>
                  Conjunction Search
                  <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                </>
              }
            >
              <LinkContainer to='/conjunctionSearch/standard'>
                <NavDropdown.Item active={false} className='--active-item'>
                  Standard
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to='/conjunctionSearch/expert'>
                <NavDropdown.Item active={false} className='--active-item'>
                  Expert Mode
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>

            {/* The "Virtual Observatory" dropdown */}
            {/*
              The `title` prop is used to replace the default dropdown icon with a `react-icon`
              The `active` prop is used to remove any lingering styles that result from clicking an
              element that is not a `NavDropdown.Item`
            */}
            <NavDropdown
              alignRight
              id='basic-nav-dropdown'
              className={
                subnav === SUBNAV_OBSERVATORY ? 'nav-link--active' : ''
              }
              title={
                <>
                  Virtual Observatory
                  <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                </>
              }
            >
              <LinkContainer to='/vo'>
                <NavDropdown.Item active={false} className='--active-item'>
                  About
                </NavDropdown.Item>
              </LinkContainer>

              <LinkContainer to='/keogramist'>
                <NavDropdown.Item active={false} className='--active-item'>
                  Keogramist
                </NavDropdown.Item>
              </LinkContainer>

              <LinkContainer to='/eventExplorer'>
                <NavDropdown.Item active={false} className='--active-item'>
                  Event Explorer
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>

            {/* The "Citizen Science" dropdown */}
            {/*
              The `title` prop is used to replace the default dropdown icon with a `react-icon`
              The `active` prop is used to remove any lingering styles that result from clicking an
              element that is not a `NavDropdown.Item`
            */}
            <NavDropdown
              alignRight
              id='basic-nav-dropdown'
              className={subnav === SUBNAV_CITIZEN ? 'nav-link--active' : ''}
              title={
                <>
                  Citizen Science
                  <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                </>
              }
            >
              {/* auroral zone */}
              <NavDropdown.Item href='https://auroralzone.org' target='_blank'>
                Auroral Zone
              </NavDropdown.Item>

              {/* swarm-aurora satellite finder */}
              <NavDropdown.Item
                href='https://swarm-aurora.com/satelliteFinder'
                target='_blank'
              >
                Satellite Finder
              </NavDropdown.Item>
            </NavDropdown>

            {/* The "Data" dropdown */}
            {/*
              The `title` prop is used to replace the default dropdown icon with a `react-icon`
              The `active` prop is used to remove any lingering styles that result from clicking an
              element that is not a `NavDropdown.Item`
            */}
            <NavDropdown
              alignRight
              id='basic-nav-dropdown'
              className={subnav === SUBNAV_DATA ? 'nav-link--active' : ''}
              title={
                <>
                  Data
                  <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                </>
              }
            >
              {/* about the data */}
              <LinkContainer to='/data/about'>
                <NavDropdown.Item active={false} className='--active-item'>
                  About the Data
                </NavDropdown.Item>
              </LinkContainer>

              {/* data sources */}
              <LinkContainer to='/data/statistics'>
                <NavDropdown.Item active={false} className='--active-item'>
                  Data Sources
                </NavDropdown.Item>
              </LinkContainer>

              {/* data availability */}
              <LinkContainer to='/data/availability'>
                <NavDropdown.Item active={false} className='--active-item'>
                  Data Availability
                </NavDropdown.Item>
              </LinkContainer>

              {/* data standards */}
              <LinkContainer to='/data/standards'>
                <NavDropdown.Item active={false} className='--active-item'>
                  Data Standards
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>

            {/* The "Developers" dropdown */}
            {/*
              The `title` prop is used to replace the default dropdown icon with a `react-icon`
              The `active` prop is used to remove any lingering styles that result from clicking an
              element that is not a `NavDropdown.Item`
            */}
            <NavDropdown
              alignRight
              id='basic-nav-dropdown'
              className={subnav === SUBNAV_DEVELOPERS ? 'nav-link--active' : ''}
              title={
                <>
                  Developers
                  <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                </>
              }
            >
              {/* api and libraries */}
              <LinkContainer to='/data/apiLibraries'>
                <NavDropdown.Item active={false} className='--active-item'>
                  About
                </NavDropdown.Item>
              </LinkContainer>

              {/* documentation */}
              <NavDropdown.Item
                href='https://docs.aurorax.space/code/overview'
                target='_blank'
              >
                Developer Zone Docs
              </NavDropdown.Item>

              {/* pyaurorax */}
              <NavDropdown.Item
                href='https://github.com/aurorax-space/pyaurorax'
                target='_blank'
              >
                PyAuroraX
              </NavDropdown.Item>

              {/* idl-aurorax */}
              <NavDropdown.Item
                href='https://github.com/aurorax-space/idl-aurorax'
                target='_blank'
              >
                IDL-AuroraX
              </NavDropdown.Item>
            </NavDropdown>

            {/* documentation */}
            {windowLength > 1300 && (
              <Nav.Link href='https://docs.aurorax.space' target='_blank'>
                Documentation
              </Nav.Link>
            )}

            {/* The "More" dropdown */}
            {/*
              The `title` prop is used to replace the default dropdown icon with a `react-icon`
              The `active` prop is used to remove any lingering styles that result from clicking an
              element that is not a `NavDropdown.Item`
            */}
            <NavDropdown
              alignRight
              id='basic-nav-dropdown'
              className={subnav === SUBNAV_MORE ? 'nav-link--active' : ''}
              title={
                <>
                  More
                  <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                </>
              }
            >
              {/* about aurorax */}
              <LinkContainer to='/about'>
                <NavDropdown.Item active={false} className='--active-item'>
                  About AuroraX
                </NavDropdown.Item>
              </LinkContainer>
              {/* contact us */}
              <LinkContainer to='/contact'>
                <NavDropdown.Item active={false} className='--active-item'>
                  Contact Us
                </NavDropdown.Item>
              </LinkContainer>
              {/* documentation (conditionally rendered) */}
              {windowLength <= 1300 && (
                <NavDropdown.Item
                  active={false}
                  className='--active-item'
                  href='https://docs.aurorax.space'
                  target='_blank'
                >
                  Documentation
                </NavDropdown.Item>
              )}
            </NavDropdown>

            {/* login button */}
            {!auth && (
              <NavLink to='/login' className='btn-login'>
                Login
              </NavLink>
            )}

            {/* other nav items requiring authentication */}
            {auth && (
              <NavDropdown
                alignRight
                id='basic-nav-dropdown'
                title={
                  <>
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      fixedWidth
                      style={{ height: '20px', width: '20px' }}
                    />
                    <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                  </>
                }
              >
                <NavDropdown.Item disabled>Hello, {username}</NavDropdown.Item>

                <NavDropdown.Divider />

                <LinkContainer to='/account/profile'>
                  <NavDropdown.Item active={false} className='--active-item'>
                    My Profile
                  </NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to='/account/manageData'>
                  <NavDropdown.Item active={false} className='--active-item'>
                    Manage Data Sources
                  </NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to='/account/requestApiKey'>
                  <NavDropdown.Item active={false} className='--active-item'>
                    Request API Key
                  </NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to='/account/checkApiKey'>
                  <NavDropdown.Item active={false} className='--active-item'>
                    Check API Key
                  </NavDropdown.Item>
                </LinkContainer>

                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => signOutProcedure()}>
                  Log Out
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </ReactNavbar.Collapse>
      </ReactNavbar>
    </>
  );
};

export default Navbar;
