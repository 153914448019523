// Importing Components from node_modules
import React from "react";
import { NavLink } from "react-router-dom";
import GitInfo from "react-git-info/macro";

// Importing Styles
import "../styles/navbar_and_footer/Footer.scss";

// Importing Logos (images)
import cfi from "../img/logos/cfi_logo.png";
import esa from "../img/logos/esa_logo.png";
import swarmDisc from "../img/logos/swarm_disc_logo.png";
import csa from "../img/logos/csa_logo.png";
import uofc from "../img/logos/uofc_logo.png";
import dtu from "../img/logos/dtu_logo.png";
import uofa from "../img/logos/uofa_logo.png";
import unis from "../img/logos/unis_logo.svg";

/*
  Constant containing git commit information of our application as a JavaScript object. Review
  `react-git-info` for more
*/
const gitInfo = GitInfo();

const Footer = () => (
  <>
    {/* <div className="footer" /> */}
    <div className="footer-container">
      {/* Footer Main */}
      <section className="footer-main">

        <div className="link-container">
          <div className="links">
            {/* Column 1 */}
            <div className="footer-title">Researchers</div>
            <ul>
              <li>
                <a href="https://swarm-aurora.com" target="_blank" rel="noopener noreferrer">
                  Swarm-Aurora
                </a>
              </li>
              <li><NavLink to="/conjunctionSearch/standard">Conjunction Search</NavLink></li>
              <li><NavLink to="/vo">Virtual Observatory</NavLink></li>
              <li><NavLink to="/keogramist">Keogramist</NavLink></li>
              <li><NavLink to="/eventExplorer">Event Explorer</NavLink></li>
              <li><NavLink to="/data/about">About the Data</NavLink></li>
              <li>
                <a href="https://docs.aurorax.space" target="_blank" rel="noopener noreferrer">
                  Documentation
                </a>
              </li>
              <li><NavLink to="/data/apiLibraries">API and Other Tools</NavLink></li>
              <li>
                <a href="https://github.com/aurorax-space/pyaurorax" target="_blank" rel="noopener noreferrer">
                  PyAuroraX
                </a>
              </li>
              <li>
                <a href="https://github.com/aurorax-space/idl-aurorax" target="_blank" rel="noopener noreferrer">
                  IDL-AuroraX
                </a>
              </li>
              <li><NavLink to="/data/statistics">Data Sources</NavLink></li>
              <li><NavLink to="/data/availability">Data Availability</NavLink></li>
              <li><NavLink to="/data/standards">Data Standards</NavLink></li>
            </ul>
          </div>

          {/* Column 2 */}
          <div className="links">
            <div className="footer-title">Citizen Science</div>
            <ul>
              <li>
                <a href="https://auroralzone.org" target="_blank" rel="noopener noreferrer">
                  Auroral Zone
                </a>
              </li>
              <li>
                <a href="https://swarm-aurora.com/satelliteFinder" target="_blank" rel="noopener noreferrer">
                  Satellite Finder
                </a>
              </li>
            </ul>
          </div>

          {/* Column 3 */}
          <div className="links">
            <div className="footer-title">Other</div>
            <ul>
              <li><NavLink to="/about">About AuroraX</NavLink></li>
              <li><NavLink to="/contact">Contact Us</NavLink></li>
              <li><NavLink to="/login">Login</NavLink></li>
            </ul>
          </div>
        </div>

        <div className="images">
          <img
            alt="cfi logo"
            src={cfi}
            style={{ maxWidth: "175px" }}
          />
          <img
            alt="esa logo"
            src={esa}
            style={{ maxWidth: "125px" }}
          />
          <img
            alt="swarm disc logo"
            src={swarmDisc}
            style={{ maxWidth: "125px" }}
          />
          <img
            alt="csa logo"
            src={csa}
            style={{ maxWidth: "80px" }}
          />
          <br />
          <hr style={{ borderTop: "1px solid rgb(77, 77, 77)" }} />
          <img
            alt="ucalgary logo"
            src={uofc}
            style={{ maxWidth: "175px" }}
          />
          <img
            alt="dtu logo"
            src={dtu}
            style={{ maxWidth: "185px" }}
          />
          <img
            alt="ualberta logo"
            src={uofa}
            style={{ maxWidth: "175px" }}
          />
          <img
            alt="unis logo"
            src={unis}
            style={{ maxWidth: "175px" }}
          />
        </div>
      </section>

      {/* Copyright */}
      <section className="copyright-wrapper">
        <p style={{ margin: "0 0 0.5rem" }}>
          Version 2.0-
          {gitInfo.commit.shortHash}
        </p>
        <p style={{ margin: "0 0 1.75rem" }}>
          Copyright ©
          {" "}
          {new Date().getFullYear()}
          {" "}
          -
          {" "}
          <a href="https://www.ucalgary.ca/aurora" target="_blank" rel="noopener noreferrer">
            Auroral Imaging Group
          </a>
          ,{" "}
          <a href="https://www.ucalgary.ca" target="_blank" rel="noopener noreferrer">
            University of Calgary
          </a>
        </p>
      </section>
    </div>
  </>
);

export default Footer;
