// Importing Components from node_modules
import React, { Suspense, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// Importing Project-defined Components
import Navbar from "./Navbar";
import Footer from "./Footer";

import { useGoogleAnalytics } from "../utils/analytics";

// Importing the page components for routing
const AboutPage = React.lazy(() => import("../pages/AboutPage"));
const AboutDataPage = React.lazy(() => import("../pages/AboutDataPage"));
const ApiLibrariesPage = React.lazy(() => import("../pages/ApiLibrariesPage"));
const ContactPage = React.lazy(() => import("../pages/ContactPage"));
const DataAvailabilityPage = React.lazy(() => import("../pages/data-availability/DataAvailabilityPage"));
const DataStandardsPage = React.lazy(() => import("../pages/DataStandardsPage"));
const DataSourcesPage = React.lazy(() => import("../pages/data-sources/DataSourcesPage"));
const EventExplorerPage = React.lazy(() => import("../pages/EventExplorerPage"));

// For some reason, making the DropDownPageContainer load lazily messes up the styling on various
// RSuite Checkpicker components throughout the site, including Keogramist, data availability, and stats.
// So it's being loaded as a normal import here to avoid those issues.
import DropDownPageContainer from "../pages/conjunction-search/dropdown-page/DropDownPage";
const FreeFormPageContainer = React.lazy(() => import("../pages/conjunction-search/freeform-page/FreeFormPage"));
const HomePage = React.lazy(() => import("../pages/HomePage"));
const VirtualObservatoryPage = React.lazy(() => import("../pages/VirtualObservatoryPage"));

import { AuthRoute, UnauthRoute } from "./ProtectedRoutes";
import AuthenticateAccountProvider from "../pages/account/context/AuthenticateAccountContext";
import CreateAccountProvider from "../pages/account/context/CreateAccountContext";

const LoginPage = React.lazy(() => import("../pages/account/LoginPage"));
const ConfirmedLoginPage = React.lazy(() => import("../pages/account/ConfirmedLoginPage"));
const CreateAccountsPage = React.lazy(() => import("../pages/account/CreateAccountsPage"));
const ResetPasswordPage = React.lazy(() => import("../pages/account/ResetPasswordPage"));
const ProfileSettingsPage = React.lazy(() => import("../pages/account/settings/ProfileSettingsPage"));
const ManageDataPage = React.lazy(() => import("../pages/account/settings/ManageDataPage"));
const CheckApiKeyPage = React.lazy(() => import("../pages/account/settings/CheckApiKeyPage"));
const ApiRequestPage = React.lazy(() => import("../pages/account/settings/ApiRequestPage"));

// import Explorer from "../pages/keogramist/Explorer";
const Explorer = React.lazy(() => import("../pages/keogramist/Explorer"));
const About = React.lazy(() => import("../pages/keogramist/About"));

/**
 * When React Router switches to different pages
 * it unmounts the current page, which then loses state information.
 * We want the Explorer page to maintain search, result, and filter state
 * between page navigations (i.e. to About and back). To do this, we pass
 * Explorer a getter and setter via props, allowing it to maintain a copy
 * of its state at the App level. This requires a useEffect hook in
 * Explorer which executes the callback on unmount/cleanup to pass the
 * last state.
 */
let explorerState;
function storeExplorerState(newState) { explorerState = { ...newState }; }
function getExplorerState() { return explorerState; }

// helper to get the value of a query param from the url and pass it into a page
function getQueryParam(location, param) {
  const params = new URLSearchParams(location.search);
  return params.get(param);
}

/*
 * Function used to update each page's <title> tag and render the navbar
 * and footer. the title tag is typically found in the public folder (index.html),
 * and would otherwise be static.
 */
const Page = ({ navbarClass = null, ...props }) => {
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  return (
    <Container fluid className="p-0 h-100 d-flex" style={{ flexDirection: "column", justifyContent: "space-between" }} >
      <Row className="no-gutters">
        <Col xs={12} className="p-0 m-0">
          <Navbar className={navbarClass} />
        </Col>

        <Col xs={12} className="p-0 m-0">
          {props.children}
        </Col>
      </Row>

      <Row className="no-gutters">
        <Col className="p-0 m-0">
          <Footer />
        </Col>
      </Row>
    </Container>

  );
};


/*
 * The following is used to setup the routing for the application through
 * `react-router-dom`. Also the current method ensures that the Navbar and Footer
 * are automatically rendered onto every page. This can be changed by simply removing
 * them from a routes render property.
 */
const AuroraXRoutes = () => {
  // Used to determine the current route (aka pathname) of the application
  useGoogleAnalytics();
  let location = useLocation();

  return (
    <Routes>
      {/* Route to the homepage */}
      <Route
        exact
        path="/"
        element={
          <>
            <Page title="AuroraX" navbarClass="home-nav">
              <Suspense fallback={<div style={{ backgroundColor: "black" }}></div>}>
                <HomePage />
              </Suspense>
            </Page>
          </>
        }
      />
      {/* Route to the Conjunction Search pages */}
      <Route
        path="/conjunctionSearch/standard"
        element={
          <>
            <Page title="AuroraX - Conjunction Search">
              <DropDownPageContainer />
            </Page>
          </>
        }
      />
      <Route
        path="/conjunctionSearch/expert"
        element={
          <>
            <Page title="AuroraX - Conjunction Search">
              <Suspense fallback={<div></div>}>
                <FreeFormPageContainer />
              </Suspense>
            </Page>
          </>
        }
      />
      <Route
        exact
        path="/conjunctionSearch/dropdowns"
        element={
          <Navigate to="/conjunctionSearch/standard" />
        }
      />
      <Route
        exact
        path="/conjunctionSearch/freeform"
        element={
          <Navigate to="/conjunctionSearch/expert" />
        }
      />

      {/* Route to the Virtual Observatory page */}
      <Route
        exact
        path="/vo"
        element={
          <>
            <Page title="AuroraX - Virtual Observatory">
              <Suspense fallback={<div></div>}>
                <VirtualObservatoryPage />
              </Suspense>
            </Page>
          </>
        }
      />

      {/* Route to the Keogramist page */}
      <Route
        exact
        path="/keogramist"
        element={
          <>
            <Page title="AuroraX - Keogramist">
              <Suspense fallback={<div></div>}>
                <Explorer
                  beta={getQueryParam(location, "beta")}
                  shareState={getQueryParam(location, "share")}
                  getState={getExplorerState} storeState={storeExplorerState} />
              </Suspense>
            </Page>
          </>
        }
      />

      {/* Route to the Vizworx Event Explorer page */}
      <Route
        exact
        path="/eventExplorer"
        element={
          <>
            <Page title="AuroraX - Event Explorer">
              <Suspense fallback={<div></div>}>
                <EventExplorerPage />
              </Suspense>
            </Page>
          </>
        }
      />

      {/* Route to the Keogramist About page */}
      <Route
        exact
        path="/keogramist/about"
        element={
          <>
            <Page title="AuroraX - About Keogramist">
              <Suspense fallback={<div></div>}>
                <About />
              </Suspense>
            </Page>
          </>
        }
      />

      {/* Route to the Data page */}
      <Route
        exact
        path="/data/about"
        element={
          <>
            <Page title="AuroraX - About Data">
              <Suspense fallback={<div></div>}>
                <AboutDataPage />
              </Suspense>
            </Page>
          </>
        }
      />

      {/* Route to the API page */}
      <Route
        exact
        path="/data/apiLibraries"
        element={
          <>
            <Page title="AuroraX - API and Other Tools">
              <Suspense fallback={<div></div>}>
                <ApiLibrariesPage />
              </Suspense>
            </Page>
          </>
        }
      />

      {/* Route to the Data Standards page */}
      <Route
        exact
        path="/data/standards"
        element={
          <>
            <Page title="AuroraX - Data Standards">
              <Suspense fallback={<div></div>}>
                <DataStandardsPage />
              </Suspense>
            </Page>
          </>
        }
      />

      {/* Route to the Data Availability page */}
      <Route
        exact
        path="/data/availability"
        element={
          <>
            <Page title="AuroraX - Data Availability">
              <Suspense fallback={<div></div>}>
                <DataAvailabilityPage />
              </Suspense>
            </Page>
          </>
        }
      />

      {/* Routes to the Data Statistics page */}
      {
        ["/data/statistics", "/data/information"].map((path, index) => {
          return <Route
            exact
            path={path}
            key={`stats-route-${index}`}
            element={
              <>
                <Page title="AuroraX - Data Statistics">
                  <Suspense fallback={<div></div>}>
                    <DataSourcesPage />
                  </Suspense>
                </Page>
              </>
            }
          />;
        })
      }

      {/* Routes to the About AuroraX page */}
      {
        ["/about", "/more/about"].map((path, index) => {
          return <Route
            exact
            path={path}
            key={`about-route-${index}`}
            element={
              <>
                <Page title="AuroraX - About">
                  <Suspense fallback={<div></div>}>
                    <AboutPage />
                  </Suspense>
                </Page>
              </>
            }
          />;
        })
      }

      {/* Routes to the Contact Us page */}
      {
        ["/contact", "/more/contact"].map((path, index) => {
          return <Route
            exact
            path={path}
            key={`contact-route-${index}`}
            element={
              <>
                <Page title="AuroraX - Contact Us">
                  <Suspense fallback={<div></div>}>
                    <ContactPage />
                  </Suspense>
                </Page>
              </>
            }
          />;
        })
      }

      {/* Route to the Login page */}
      <Route
        exact
        path="/login"
        element={
          <UnauthRoute>
            <>
              <Page title="AuroraX - Login">
                <Suspense fallback={<div></div>}>
                  <AuthenticateAccountProvider>
                    <LoginPage />
                  </AuthenticateAccountProvider>
                </Suspense>
              </Page>
            </>
          </UnauthRoute>
        }
      />

      {/* Route to the Login page but to notify user their account has been confirmed */}
      <Route
        exact
        path="/welcome"
        element={
          <UnauthRoute>
            <>
              <Page title="AuroraX - Welcome">
                <Suspense fallback={<div></div>}>
                  <AuthenticateAccountProvider>
                    <ConfirmedLoginPage />
                  </AuthenticateAccountProvider>
                </Suspense>
              </Page>
            </>
          </UnauthRoute>
        }
      />

      {/* Create an Account */}
      <Route
        exact
        path="/createAccount"
        element={
          <UnauthRoute>
            <>
              <Page title="AuroraX - Create an Account">
                <Suspense fallback={<div></div>}>
                  <CreateAccountProvider>
                    <CreateAccountsPage />
                  </CreateAccountProvider>
                </Suspense>
              </Page>
            </>
          </UnauthRoute>
        }
      />

      {/* Reset account password */}
      <Route
        exact
        path="/resetPassword"
        element={
          <UnauthRoute>
            <>
              <Page title="AuroraX - Reset Password">
                <Suspense fallback={<div></div>}>
                  <ResetPasswordPage />
                </Suspense>
              </Page>
            </>
          </UnauthRoute>
        }
      />

      {/* Account settings */}
      <Route
        exact
        path="/account/profile"
        element={
          <AuthRoute>
            <>
              <Page title="AuroraX - Profile Settings">
                <Suspense fallback={<div></div>}>
                  <ProfileSettingsPage />
                </Suspense>
              </Page>
            </>
          </AuthRoute>
        }
      />

      {/* Manage Users Data Source */}
      <Route
        exact
        path="/account/manageData"
        element={
          <AuthRoute>
            <>
              <Page title="AuroraX - Manage Your Data">
                <Suspense fallback={<div></div>}>
                  <ManageDataPage />
                </Suspense>
              </Page>
            </>
          </AuthRoute>
        }
      />

      {/* Route to the check your API Key after logging in */}
      <Route
        exact
        path="/account/checkApiKey"
        element={
          <AuthRoute>
            <>
              <Page title="AuroraX - Check API Key">
                <Suspense fallback={<div></div>}>
                  <CheckApiKeyPage />
                </Suspense>
              </Page>
            </>
          </AuthRoute>
        }
      />

      {/* Route to the Request API Key after logging in */}
      <Route
        exact
        path="/account/requestApiKey"
        element={
          <AuthRoute>
            <>
              <Page title="AuroraX - Request API Key">
                <Suspense fallback={<div></div>}>
                  <ApiRequestPage />
                </Suspense>
              </Page>
            </>
          </AuthRoute>
        }
      />

      {/* Redirected to home when searching for a non-existent path */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AuroraXRoutes;
